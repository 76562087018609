import React, { useEffect, useState } from 'react';
import '../Builder_prompt_components/global_theme/fonts.css';
import favicon from '../../assets/blackborderfavicon.svg';
import supabase from '../../config/supabse';
import '../SignIn/spinner.css'

function Admin() {
    const [wishlist, setWishlist] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const [grantingAccess, setGrantingAccess] = useState(false)
    const [revokingAccess, setRevokingAccess] = useState(false)
    const [sendingInvitation, setSendingInvitation] = useState(false)

    const entriesPerPage = 10;

    useEffect(() => {
        const fetchWishlist = async () => {
            const { data, error } = await supabase
                .from('wishlist')
                .select('*');

            if (error) {
                console.error('Error fetching wishlist:', error);
            } else {
                setWishlist(data);
            }
        };

        fetchWishlist();
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
        setCurrentPage(1); // Reset to first page on search
    };

    const filteredWishlist = wishlist.filter((item) =>
        item.email.toLowerCase().includes(searchTerm)
    );

    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = filteredWishlist.slice(indexOfFirstEntry, indexOfLastEntry);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const totalPages = Math.ceil(filteredWishlist?.length / entriesPerPage);

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(itemId)
                ? prevSelectedItems.filter((id) => id !== itemId)
                : [...prevSelectedItems, itemId]
        );
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(currentEntries.map((item) => item.id));
        }
        setSelectAll(!selectAll);
    };

    const updateAccess = async (grant) => {
        const { data, error } = await supabase
            .from('wishlist')
            .update({ access: grant })
            .in('id', selectedItems);

        if (error) {
            console.error('Error updating access:', error);
        } else {
            setWishlist((prevWishlist) =>
                prevWishlist.map((item) =>
                    selectedItems.includes(item.id)
                        ? { ...item, access: grant }
                        : item
                )
            );
            setSelectedItems([]);
            setSelectAll(false);
        }
    };

    const GrantAccess = () => {
        setGrantingAccess(true)
        updateAccess(true);
        setGrantingAccess(false)
    };

    const RevokeAccess = () => {
        setRevokingAccess(true)
        updateAccess(false);
        setRevokingAccess(false)
    };

    const sendInvitationEmails = async () => {
        for (const itemId of selectedItems) {
            const item = wishlist.find((item) => item.id === itemId);
            if (item) {
                const { data, error } = await supabase.auth.signInWithOtp({
                    email: item.email,
                });
                if (error) {
                    console.error('Error sending invitation:', error);
                }
              
            }
        }
    };





    const handleSendInvitation = async () => {
        setSendingInvitation(true)
        await sendInvitationEmails();
        setSendingInvitation(false)
        // Optionally, you can refresh the wishlist or provide feedback
    };

    return (
        <div className='w-auto h-[100vh] overflow-hidden bg-white font_manrope'>
            <div className='shadow-md px-20 py-7 border-b-2 border-b-gray-200 rounded-md w-[100%] sm:w-[100%] h-auto flex flex-row justify-between items-center'>
                <div className='flex flex-row gap-5 items-center'>
                    <div className='rounded-full w-[50px] h-[50px]'>
                        <img src={favicon} alt="" />
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-bold text-xl text-black'>Admin</h1>
                        <p className='text-lg text-zinc-800'>admin@helloworld.to</p>
                    </div>
                </div>
                <div className='flex flex-row'>
                    <p className='text-zinc-800 text-xl'>
                        Wishlist
                    </p>
                </div>
            </div>
            <div className='p-20 w-[100%] sm:w-[100%] h-full bg-white'>
                <div className="flex flex-col">
                    <div className="-m-1.5 overflow-x-auto">
                        <div className="p-1.5 min-w-full inline-block align-middle">
                            <div className="border rounded-lg divide-y divide-gray-200">
                                <div className="py-3 px-4 flex flex-row items-center justify-between">
                                    <div className="relative max-w-xs">
                                        <label className="sr-only">Search</label>
                                        <input
                                            type="text"
                                            name="hs-table-with-pagination-search"
                                            id="hs-table-with-pagination-search"
                                            className="py-2 px-3 ps-9 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                                            placeholder="Search for items"
                                            value={searchTerm}
                                            onChange={handleSearch}
                                        />
                                        <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-3">
                                            <svg
                                                className="size-4 text-gray-400"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <circle cx="11" cy="11" r="8"></circle>
                                                <path d="m21 21-4.3-4.3"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex gap-4">
                                        {
                                            grantingAccess ? (<div className='spinner'></div>) : (<button
                                                onClick={GrantAccess}
                                                className="bg-gray-100 text-zinc-700 px-4 py-2 rounded-md hover:bg-zinc-400 hover:text-white"
                                            >
                                                Grant Access
                                            </button>)
                                        }

                                        {
                                            revokingAccess ? (<div className='spinner'></div>) : (<button
                                                onClick={RevokeAccess}
                                                className="bg-gray-100 text-zinc-700 px-4 py-2 rounded-md hover:bg-zinc-400 hover:text-white"
                                            >
                                                Revoke Access
                                            </button>)
                                        }



                                        {
                                            sendingInvitation ? (<div className='spinner'></div>) : (
                                                <button
                                                    onClick={handleSendInvitation}
                                                    className="bg-gray-100 text-zinc-700 px-4 py-2 rounded-md hover:bg-zinc-400 hover:text-white"
                                                >
                                                    Send Invitation
                                                </button>
                                            )
                                        }

                                    </div>
                                </div>
                                <div className="overflow-hidden">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="py-3 px-4 pe-0">
                                                    <div className="flex items-center h-5">
                                                        <input
                                                            id="hs-table-pagination-checkbox-all"
                                                            type="checkbox"
                                                            className="border-gray-200 rounded text-blue-600 focus:ring-blue-500"
                                                            checked={selectAll}
                                                            onChange={handleSelectAll}
                                                        />
                                                        <label
                                                            htmlFor="hs-table-pagination-checkbox-all"
                                                            className="sr-only"
                                                        >
                                                            Checkbox
                                                        </label>
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                                                >
                                                    Email
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                                                >
                                                    Access
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                                                >
                                                    Date Added
                                                </th>
                                                {/* <th
                          scope="col"
                          className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
                        >
                          Access Action
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
                        >
                          Send Invitation
                        </th> */}
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {currentEntries.map((item) => (
                                                <tr key={item.id}>
                                                    <td className="py-3 ps-4">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                id={`hs-table-pagination-checkbox-${item.id}`}
                                                                type="checkbox"
                                                                className="border-gray-200 rounded text-blue-600 focus:ring-blue-500"
                                                                checked={selectedItems.includes(item.id)}
                                                                onChange={() => handleCheckboxChange(item.id)}
                                                            />
                                                            <label
                                                                htmlFor={`hs-table-pagination-checkbox-${item.id}`}
                                                                className="sr-only"
                                                            >
                                                                Checkbox
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                        {item.email}
                                                    </td>
                                                    <td className={`px-6 py-4 whitespace-nowrap text-sm font-bold ${item.access ? "text-green-500" : "text-red-500"}`}>
                                                        {item.access ? 'Granted' : 'Revoked'}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {item.created_at}
                                                    </td>
                                                    {/* <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                            <button
                              type="button"
                              className={`text-${item.access ? "red" : "green"}-600 hover:text-${item.access ? "red" : "green"}-800`}
                              onClick={() => item.access ? RevokeAccess(item.id) : GrantAccess(item.id)}
                            >
                              {item.access ? 'Revoke Access' : 'Grant Access'}
                            </button>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                            <button
                              type="button"
                              className="text-blue-600 hover:text-blue-800"
                              onClick={() => handleCheckboxChange(item.id)}
                            >
                              Send Invitation
                            </button>
                          </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="py-3 px-4">
                            <div className="flex items-center justify-between">
                                <div className="text-sm text-gray-500">
                                    Page {currentPage} of {totalPages}
                                </div>
                                <div className="flex gap-2">
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <button
                                            key={i + 1}
                                            onClick={() => paginate(i + 1)}
                                            className={`px-3 py-1.5 text-sm font-medium rounded-lg ${currentPage === i + 1 ? 'bg-zinc-500 text-white' : 'bg-gray-100 text-gray-600'}`}
                                        >
                                            {i + 1}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Admin;
