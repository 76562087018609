import React, { useEffect, useState } from "react";
import supabase from "../../../../config/supabse";
import { useNavigate, useParams } from "react-router-dom";


const WebsiteCard = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => {
          navigate(`/loading/${props.authId}/12988/1`);
        }}
        className="w-full h-[10vh] border border-gray-200 rounded-[10px] px-7 py-4 flex flex-row items-center cursor-pointer hover:bg-gray-200   gap-5"
      >
        <div className="h-[50px] w-[50px] border border-gray-200 bg-gray-100 rounded-full flex items-center justify-center">
          <span className="material-symbols-outlined text-[20px] font-thin">
            language
          </span>
        </div>
        <div classolame="flex flex-cl">
          <div className="flex flex-row gap-3 text-sm">
            <p className="font-bold">Domain: </p>
            <p>{props.domain}.helloworld.to</p>
          </div>
          <div className="flex flex-row gap-3 text-sm">
            <p className="font-bold">Primary Domain: </p>
            <p>
              {props.primaryDomain
                ? `${props.primaryDomain}`
                : `${props.domain}.helloworld.to`}
            </p>
          </div>
          <div className="flex flex-row gap-3 text-sm">
            <p className="font-bold">AuthId : </p>
            <p>{props.authId}</p>
          </div>
        </div>
      </div>
    </>
  );
};
const AdminEditorMain = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [myId,setMyId] =useState(null)
  const [myWebsite, setMyWebsite] =useState(null)
  const [currentWebsite, setCurrentWebsite] =useState(null)
  const {credId} = useParams()


  useEffect(()=>{
    if(data && myId){
        setMyWebsite(data.find(item => item.userId === myId));       
        setCurrentWebsite(data.find(item => item.userId === credId))
    }
  },[data, myId])

  useEffect(()=>{
    const getSession = async () => {
        const { data, error } = await supabase.auth.getSession();
        if (error) {
          console.error("Error retrieving session:", error.message);
          return;
        }
        if (data) {
          if (
            data &&
            data.session &&
            data.session.user.id &&
            data.session.access_token
          ) {
            setMyId(data.session.user.id)
           
          }
        }
      };
      getSession();
},[])




  useEffect(() => {
    const fetchUsersAndWebsites = async () => {
      const { data, error } = await supabase.from("websites").select(`*`);

      if (error) {
        setError(error);
        console.log("Error:", error);
      } else {
        setData(data);
        console.log("All websites: , ", data);
      }
    };

    fetchUsersAndWebsites();
  }, []); // Empty dependency array means this will run only once on component mount


 



  return (
    <div className=" h-full w-full p-10">
      <div className=" h-full w-full">
        <div className="flex flex-col w-full h-auto">
          <h1 className="font-semibold mb-5">My Website</h1>
          
          <WebsiteCard
            domain={myWebsite?.domain}
            primaryDomain={myWebsite?.customDomain}
            authId={myWebsite?.userId}
          
          />
        </div>

        <div className="flex flex-col w-full h-auto mt-10">
          <h1 className="font-semibold mb-5">Current Active Webiste</h1>
          <WebsiteCard
          domain={currentWebsite?.domain}
          primaryDomain={currentWebsite?.customDomain}
          authId={currentWebsite?.userId}
        
        />
        </div>

        <div className="flex flex-col w-full h-auto mt-10">
          <h1 className="font-semibold">Other Websites</h1>
          <div className="flex flex-col gap-5 mt-5 mb-10">
            {data.map((item, index) => (
              <WebsiteCard
                key={index}
                domain={item.domain}
                primaryDomain={item.customDomain}
                authId={item.userId}
              />
            ))}
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default AdminEditorMain;
